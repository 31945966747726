<template>
  <div class="tryAssessmentHome">
    <div class="top">
      <el-radio-group v-model="radio" class="dx">
        <el-radio :label="'CRT'">OK</el-radio>
        <el-radio :label="'RGP'">RGP</el-radio>
        <el-radio :label="'SCL'">SCL</el-radio>
      </el-radio-group>
      <p class="pp">品牌</p>
      <el-select class="sr" v-model="typeVal" placeholder="请选择" @change="ppbh">
        <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" @click="add">增加试戴</el-button>
      <UPfile :type="'ContactLensesTry,RGP_D,RGP_J,VST_D,VST_J'" :isShowImgList="false" ></UPfile>
    </div>
    <div class="t-box">
      <ul class="title">
        <li v-for="(item, idx) in zjArr" :class="topIdx==idx?'xzs':''" @click="topIdx=idx">
          <i class="el-icon-error" @click="dele(idx)"></i>
          试戴{{idx+1}}
        </li>
      </ul>
    </div>

    <div class="content">
      <div v-for="(item, idx) in zjArr">
        <okTry v-if="item.S_OP_Json.S_OP_CG_Type=='CRT' && idx==topIdx" :name="typeVal" :postData="item"></okTry>
        <OkOtherTry v-if="item.S_OP_Json.S_OP_CG_Type=='VST' && idx == topIdx" :name="typeVal" :postData="item"></OkOtherTry>
        <RGPTry v-if="item.S_OP_Json.S_OP_CG_Type=='RGP' && idx==topIdx" :name="typeVal" :postData="item"></RGPTry>
        <SCLTry v-if="item.S_OP_Json.S_OP_CG_Type=='SCL' && idx==topIdx" :name="typeVal" :postData="item"></SCLTry>
      </div>
    </div>

    <div class="bottom">
      <el-button type="primary" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "tryAssessmentHome",
  data() {
    return {
      typeVal: '',
      typeValzd: '',
      radio: 'CRT',
      input: '',
      options: [
        {
          value: 'CRT',
          label: 'CRT'
        },
        {
          value: '露晰得',
          label: '露晰得'
        },
        {
          value: '欧几里德',
          label: '欧几里德'
        },
        {
          value: '菁视',
          label: '菁视'
        },
        {
          value: 'DreamLite',
          label: 'DreamLite'
        },
        {
          value: '梦戴维',
          label: '梦戴维'
        },
        {
          value: '享泰',
          label: '享泰'
        },
        {
          value: '阿尔法',
          label: '阿尔法'
        },
        {
          value: '普诺瞳',
          label: '普诺瞳'
        },
        {
          value: '其他',
          label: '其他'
        },
      ],
      zjArr: [],
      topIdx: 0,
      OKarr: [
        {
          value: 'CRT',
          label: 'CRT'
        },
        {
          value: '露晰得',
          label: '露晰得'
        },
        {
          value: '欧几里德',
          label: '欧几里德'
        },
        {
          value: '菁视',
          label: '菁视'
        },
        {
          value: 'DreamLite',
          label: 'DreamLite'
        },
        {
          value: '梦戴维',
          label: '梦戴维'
        },
        {
          value: '享泰',
          label: '享泰'
        },
        {
          value: '阿尔法',
          label: '阿尔法'
        },
        {
          value: '其他',
          label: '其他'
        },
      ],
      RGParr: [
        {
          value: '美尼康Z',
          label: '美尼康Z'
        },
        {
          value: '美尼康E1',
          label: '美尼康E1'
        },
        {
          value: '美尼康EX',
          label: '美尼康EX'
        },
        {
          value: '美康',
          label: '美康'
        },
        {
          value: '蔡司',
          label: '蔡司'
        },
        {
          value: '露晰得',
          label: '露晰得'
        },
        {
          value: '梦戴维',
          label: '梦戴维'
        },
        {
          value: '亨泰',
          label: '亨泰'
        },
        {
          value: '其他',
          label: '其他'
        }
      ]
    }
  },
  watch: {
    radio: function (n, o) {
      this.typeVal = ''
      this.typeValzd = ''
      if (n == 'CRT') {
        this.options = this.OKarr
      }else {
        this.options = this.RGParr
      }
    },
    zjArr: {
      handler(n, o) {
        this.$store.commit('corneaContact/upTryCorneaContact', n)
      },
      deep: true
    }
  },
  created() {
    if (!this.$store.state.users.CsUser) {
      this.$alert('请客户登陆,选择咨询或新建咨询', '提示')
      .then(res => {
        this.$router.push({path: '/home/acceptsHome'})
      })

      this.cshTime('contact_TE_optomtry')

      return
    }

    this._api.corneaContact.getTryAssessment()
    .then(res => {
      if (res.GetListResult && res.GetListResult.length > 0) {
        for (let i = 0; i < res.GetListResult.length; i++) {
          res.GetListResult[i].S_OP_Json = this._clJson(res.GetListResult[i].S_OP_Json)
        }
        this.$store.commit('corneaContact/upTryCorneaContact', res.GetListResult)
        this.zjArr = res.GetListResult
      }
    })
  },
  methods: {
    add() {
      if (!this.typeVal) return this.$alert('请选择品牌', '提示')
      this.zjArr.push(this.datas(this.typeValzd))
      this.topIdx = this.zjArr.length - 1
    },
    dele(idx) {
      this.zjArr.splice(idx, 1)
      this.topIdx = 0
    },

    ppbh(e) {
      this.typeValzd = this.radio
      if (this.radio == 'CRT') {
        if (e != 'CRT') {
          this.typeValzd = 'VST'
        }
      }
    },

    datas(type) {
      let ExpertId = '00000000-0000-0000-0000-000000000000'
      if (this.$store.state.users.employees.ExpertId) ExpertId = this.$store.state.users.employees.ExpertId
      let obj = {}
      switch (type) {
        case 'CRT':
          obj = {
            S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
            S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
            S_OP_ExpertId: ExpertId,
            S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
            S_OP_ID: "00000000-0000-0000-0000-000000000000",
            S_OP_Json: {
              S_OP_CG_A_ActivityOD: "",
              S_OP_CG_A_ActivityOS: "",
              S_OP_CG_A_CenterOD: "",
              S_OP_CG_A_CenterOS: "",
              S_OP_CG_A_EdgeOD: "",
              S_OP_CG_A_EdgeOS: "",
              S_OP_CG_A_FitOD: "",
              S_OP_CG_A_FitOS: "",
              S_OP_CG_A_ImproveOD: "",
              S_OP_CG_A_ImproveOS: "",
              S_OP_CG_A_LYJHOD: "",
              S_OP_CG_A_LYJHOS: "",
              S_OP_CG_A_LandOD: "",
              S_OP_CG_A_LandOS: "",
              S_OP_CG_A_LocationOD: "",
              S_OP_CG_A_LocationOS: "",
              S_OP_CG_A_ReversalOD: "",
              S_OP_CG_A_ReversalOS: "",
              S_OP_CG_A_SleepTestOD: "",
              S_OP_CG_A_SleepTestOS: "",
              S_OP_CG_A_TrySituationOD: "",
              S_OP_CG_A_TrySituationOS: "",
              S_OP_CG_ChooseId: this.$store.state.physicianVisits.xzConsulting,
              S_OP_CG_IsSubmit: "",
              S_OP_CG_Order: "",
              S_OP_CG_R_DCOD: "",
              S_OP_CG_R_DCOS: "",
              S_OP_CG_R_DSOD: "",
              S_OP_CG_R_DSOS: "",
              S_OP_CG_R_FKOD: "",
              S_OP_CG_R_FKOS: "",
              S_OP_CG_R_HVIDOD: "",
              S_OP_CG_R_HVIDOS: "",
              S_OP_CG_R_SKOD: "",
              S_OP_CG_R_SKOS: "",
              S_OP_CG_R_VAOD: "",
              S_OP_CG_R_VAOS: "",
              S_OP_CG_R_XOD: "",
              S_OP_CG_R_XOS: "",
              S_OP_CG_Remark: "",
              S_OP_CG_Try_BCOD: "",
              S_OP_CG_Try_BCOS: "",
              S_OP_CG_Try_Brand: this.typeVal,
              S_OP_CG_Try_DIAOD: "",
              S_OP_CG_Try_DIAOS: "",
              S_OP_CG_Try_LZA2OD: "",
              S_OP_CG_Try_LZA2OS: "",
              S_OP_CG_Try_LZAOD: "",
              S_OP_CG_Try_LZAOS: "",
              S_OP_CG_Try_NoOD: "",
              S_OP_CG_Try_NoOS: "",
              S_OP_CG_Try_OnDiopter_DCOD: "",
              S_OP_CG_Try_OnDiopter_DCOS: "",
              S_OP_CG_Try_OnDiopter_DSOD: "",
              S_OP_CG_Try_OnDiopter_DSOS: "",
              S_OP_CG_Try_OnDiopter_VAOD: "",
              S_OP_CG_Try_OnDiopter_VAOS: "",
              S_OP_CG_Try_OnDiopter_XOD: "",
              S_OP_CG_Try_OnDiopter_XOS: "",
              S_OP_CG_Try_PowerOD: "",
              S_OP_CG_Try_PowerOS: "",
              S_OP_CG_Try_RZD2OD: "",
              S_OP_CG_Try_RZD2OS: "",
              S_OP_CG_Try_RZDOD: "",
              S_OP_CG_Try_RZDOS: "",
              S_OP_CG_Type: "CRT",
              S_OP_DTPics: "",
              S_OP_JTPics: ""
            },
            S_OP_Model: "S_OP_ContactGlass180726",
            S_OP_Time: "",
            S_OP_Type: "ContactGlass",
            S_OP_XML: "",
          }
          break
        case 'VST':
          obj = {
            S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
            S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
            S_OP_ExpertId: ExpertId,
            S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
            S_OP_ID: "00000000-0000-0000-0000-000000000000",
            S_OP_Json: {
              S_OP_CG_A_ActivityOD: "",
              S_OP_CG_A_ActivityOS: "",
              S_OP_CG_A_CenterOD: "",
              S_OP_CG_A_CenterOS: "",
              S_OP_CG_A_EdgeOD: "",
              S_OP_CG_A_EdgeOS: "",
              S_OP_CG_A_FitOD: "",
              S_OP_CG_A_FitOS: "",
              S_OP_CG_A_ImproveOD: "",
              S_OP_CG_A_ImproveOS: "",
              S_OP_CG_A_LYJHOD: "",
              S_OP_CG_A_LYJHOS: "",
              S_OP_CG_A_LandOD: "",
              S_OP_CG_A_LandOS: "",
              S_OP_CG_A_LocationOD: "",
              S_OP_CG_A_LocationOS: "",
              S_OP_CG_A_ReversalOD: "",
              S_OP_CG_A_ReversalOS: "",
              S_OP_CG_A_SleepTestOD: "",
              S_OP_CG_A_SleepTestOS: "",
              S_OP_CG_A_TrySituationOD: "",
              S_OP_CG_A_TrySituationOS: "",
              S_OP_CG_ChooseId: this.$store.state.physicianVisits.xzConsulting,
              S_OP_CG_Order: "",
              S_OP_CG_R_DCOD: "",
              S_OP_CG_R_DCOS: "",
              S_OP_CG_R_DSOD: "",
              S_OP_CG_R_DSOS: "",
              S_OP_CG_R_FKOD: "",
              S_OP_CG_R_FKOS: "",
              S_OP_CG_R_HVIDOD: "",
              S_OP_CG_R_HVIDOS: "",
              S_OP_CG_R_SKOD: "",
              S_OP_CG_R_SKOS: "",
              S_OP_CG_R_VAOD: "",
              S_OP_CG_R_VAOS: "",
              S_OP_CG_R_XOD: "",
              S_OP_CG_R_XOS: "",
              S_OP_CG_Remark: "",
              S_OP_CG_Try_ACOD: "",
              S_OP_CG_Try_ACOS: "",
              S_OP_CG_Try_Brand: this.typeVal,
              S_OP_CG_Try_CPOD: "",
              S_OP_CG_Try_CPOS: "",
              S_OP_CG_Try_DIAOD: "",
              S_OP_CG_Try_DIAOS: '',
              S_OP_CG_Try_OnDiopter_DCOD: "",
              S_OP_CG_Try_OnDiopter_DCOS: "",
              S_OP_CG_Try_OnDiopter_DSOD: "",
              S_OP_CG_Try_OnDiopter_DSOS: "",
              S_OP_CG_Try_OnDiopter_VAOD: "",
              S_OP_CG_Try_OnDiopter_VAOS: "",
              S_OP_CG_Try_OnDiopter_XOD: "",
              S_OP_CG_Try_OnDiopter_XOS: "",
              S_OP_CG_Try_RPOD: "",
              S_OP_CG_Try_RPOS: "",
              S_OP_CG_Try_TypeOD: "",
              S_OP_CG_Try_TypeOS: "",
              S_OP_CG_Type: "VST",
              S_OP_DTPics: "",
              S_OP_JTPics: "",
            },
            S_OP_Model: "S_OP_ContactGlass180726",
            S_OP_Time: "",
            S_OP_Type: "ContactGlass",
            S_OP_XML: "",
          }
          break
        case 'RGP':
          obj = {
            S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
            S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
            S_OP_ExpertId: ExpertId,
            S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
            S_OP_ID: "00000000-0000-0000-0000-000000000000",
            S_OP_Json: {
              S_OP_CG_A_ActivityOD: "",
              S_OP_CG_A_ActivityOS: "",
              S_OP_CG_A_BesideCenterOD: "",
              S_OP_CG_A_BesideCenterOS: "",
              S_OP_CG_A_CenterOD: "",
              S_OP_CG_A_CenterOS: "",
              S_OP_CG_A_EdgeOD: "",
              S_OP_CG_A_EdgeOS: "",
              S_OP_CG_A_ImproveOD: "",
              S_OP_CG_A_ImproveOS: "",
              S_OP_CG_A_LYJHOD: "",
              S_OP_CG_A_LYJHOS: "",
              S_OP_CG_A_LocationOD: "",
              S_OP_CG_A_LocationOS: "",
              S_OP_CG_A_TrySituationOD: "",
              S_OP_CG_A_TrySituationOS: "",
              S_OP_CG_ChooseId: this.$store.state.physicianVisits.xzConsulting,
              S_OP_CG_Order: "",
              S_OP_CG_R_AVGKOD: "",
              S_OP_CG_R_AVGKOS: "",
              S_OP_CG_R_DCOD: "",
              S_OP_CG_R_DCOS: "",
              S_OP_CG_R_DSOD: "",
              S_OP_CG_R_DSOS: "",
              S_OP_CG_R_FKOD: "",
              S_OP_CG_R_FKOS: "",
              S_OP_CG_R_SKOD: "",
              S_OP_CG_R_SKOS: "",
              S_OP_CG_R_VAOD: "",
              S_OP_CG_R_VAOS: "",
              S_OP_CG_R_XOD: "",
              S_OP_CG_R_XOS: "",
              S_OP_CG_Remark: "",
              S_OP_CG_Try_BCOD: "",
              S_OP_CG_Try_BCOS: "",
              S_OP_CG_Try_Brand: this.typeVal,
              S_OP_CG_Try_DIAOD: "",
              S_OP_CG_Try_DIAOS: "",
              S_OP_CG_Try_DiopterOD: "",
              S_OP_CG_Try_DiopterOS: "",
              S_OP_CG_Try_MRSOD: "",
              S_OP_CG_Try_MRSOS: "",
              S_OP_CG_Try_OnDiopter_DCOD: "",
              S_OP_CG_Try_OnDiopter_DCOS: "",
              S_OP_CG_Try_OnDiopter_DSOD: "",
              S_OP_CG_Try_OnDiopter_DSOS: "",
              S_OP_CG_Try_OnDiopter_VAOD: "",
              S_OP_CG_Try_OnDiopter_VAOS: "",
              S_OP_CG_Try_OnDiopter_XOD: "",
              S_OP_CG_Try_OnDiopter_XOS: "",
              S_OP_CG_Try_TypeOD: "",
              S_OP_CG_Try_TypeOS: "",
              S_OP_CG_Type: "RGP",
              S_OP_DTPics: "",
              S_OP_JTPics: ""
            },
            S_OP_Model: "S_OP_ContactGlass180726",
            S_OP_Time: "",
            S_OP_Type: "ContactGlass",
            S_OP_XML: "",
          }
          break
        case 'SCL':
          obj = {
            S_OP_ChooseId: this.$store.state.physicianVisits.xzConsulting,
            S_OP_CustomerId: this.$store.state.users.CsUser.CustomerId,
            S_OP_ExpertId: ExpertId,
            S_OP_HealthCheckId: "00000000-0000-0000-0000-000000000000",
            S_OP_ID: "00000000-0000-0000-0000-000000000000",
            S_OP_Json: {
              S_OP_CG_A_ActivityOD: "",
              S_OP_CG_A_ActivityOS: "",
              S_OP_CG_A_CYLLocationOD: "",
              S_OP_CG_A_CYLLocationOS: "",
              S_OP_CG_A_CoverOD: "",
              S_OP_CG_A_CoverOS: "",
              S_OP_CG_A_LYJHOD: "",
              S_OP_CG_A_LYJHOS: "",
              S_OP_CG_A_LocationOD: "",
              S_OP_CG_A_LocationOS: "",
              S_OP_CG_ChooseId: this.$store.state.physicianVisits.xzConsulting,
              S_OP_CG_Order: "",
              S_OP_CG_R_AVGKOD: "",
              S_OP_CG_R_AVGKOS: "",
              S_OP_CG_R_DCOD: "",
              S_OP_CG_R_DCOS: "",
              S_OP_CG_R_DSOD: "",
              S_OP_CG_R_DSOS: "",
              S_OP_CG_R_FKOD: "",
              S_OP_CG_R_FKOS: "",
              S_OP_CG_R_SKOD: "",
              S_OP_CG_R_SKOS: "",
              S_OP_CG_R_VAOD: "",
              S_OP_CG_R_VAOS: "",
              S_OP_CG_R_XOD: "",
              S_OP_CG_R_XOS: "",
              S_OP_CG_Try_BCOD: "",
              S_OP_CG_Try_BCOS: "",
              S_OP_CG_Try_Brand: this.typeVal,
              S_OP_CG_Try_DIAOD: "",
              S_OP_CG_Try_DIAOS: "",
              S_OP_CG_Try_DiopterOD: "",
              S_OP_CG_Try_DiopterOS: "",
              S_OP_CG_Try_MRSOD: "",
              S_OP_CG_Try_MRSOS: "",
              S_OP_CG_Try_OnDiopter_DCOD: "",
              S_OP_CG_Try_OnDiopter_DCOS: "",
              S_OP_CG_Try_OnDiopter_DSOD: "",
              S_OP_CG_Try_OnDiopter_DSOS: "",
              S_OP_CG_Try_OnDiopter_VAOD: "",
              S_OP_CG_Try_OnDiopter_VAOS: "",
              S_OP_CG_Try_OnDiopter_XOD: "",
              S_OP_CG_Try_OnDiopter_XOS: "",
              S_OP_CG_Try_TypeOD: "",
              S_OP_CG_Try_TypeOS: "",
              S_OP_CG_Type: "SCL"
            },
            S_OP_Model: "S_OP_ContactGlass180726",
            S_OP_Time: "",
            S_OP_Type: "ContactGlass",
            S_OP_XML: "",
          }
          break
      }
      return obj
    },

    save() {
      if ( this.$store.state.Time.timeObj.contact_TE_optomtry.ENTER != 0) {
        let times = ((new Date()).valueOf() - this.$store.state.Time.timeObj.contact_TE_optomtry.ENTER)/1000 + Number(this.$store.state.Time.timeObj.contact_TE_optomtry.EXIT)
        this._api.publicApi.addTime('contact_TE_optomtry,contact_TE_trailSlice,contact_TE_sliceOptometry,contact_TE_dynamic,contact_TE_static', times)
        this.jsTime('contact_TE_optomtry', 1)
      }
      this._api.corneaContact.saveTryAssessment()
    },


    cshTime(keys) {
      // console.log(this.$store.state.Time.timeObj[keys].EXIT)
      this.$store.commit('Time/upTimeObj', {
        keys: keys,
        val: {
          ENTER: (new Date()).valueOf(),
          EXIT: this.$store.state.Time.timeObj[keys].EXIT
        }
      })
    },
    jsTime(keys,types=0) {
      if (types) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: 0,
            EXIT: 0
          }
        })
        return
      }
      if (this.$store.state.Time.timeObj[keys].ENTER !== 0) {
        this.$store.commit('Time/upTimeObj', {
          keys: keys,
          val: {
            ENTER: (new Date()).valueOf(),
            EXIT: ((new Date()).valueOf() - this.$store.state.Time.timeObj[keys].ENTER)/1000 + Number(this.$store.state.Time.timeObj[keys].EXIT)
          }
        })
      }
    }
  },
  components: {
    okTry: () => import('@/components/corneaContact/tryAssessment/okTry'),
    OkOtherTry: () => import('@/components/corneaContact/tryAssessment/okOtherTry'),
    RGPTry: () => import('@/components/corneaContact/tryAssessment/RGPTry'),
    SCLTry: () => import('@/components/corneaContact/tryAssessment/SCLTry'),
    UPfile: () => import('@/components/CommonComponents/upFile')
  },
  beforeDestroy() {
    if (this.$store.state.Time.timeObj.contact_TE_optomtry.ENTER != 0) {
      this.jsTime('contact_TE_optomtry')
    }

  }
}
</script>

<style scoped lang="scss">
  .tryAssessmentHome {
    width: 100vw;
    height: 87vh;
    overflow: hidden;
  }
  .top {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: .3rem;
    .pp {
      padding-left: 1.5rem;
      padding-right: .1rem;
    }
    .sr {
      width: 2.2rem;
      margin-right: .7rem;
    }
  }
  .t-box {
    width: 90%;
    margin: 0 auto;
    padding-top: .2rem;
    border-bottom: 1px solid #40a7f6;
    overflow: auto;
    .title {
      width: 100%;
      display: flex;
      justify-content: start;
      padding-bottom: .1rem;
      li {
        padding: .1rem .2rem;
        border-radius: 5px;
        background: #efefef;
        position: relative;
        cursor: pointer;
        margin-right: .15rem;
        i {
          position: absolute;
          right: -7px;
          top: -7px;
          color: #fa0202;
          font-size: 15px;
        }
        &:hover {background: #40a7f6;color: #ffffff;}
      }
      .xzs {background: #40a7f6;color: #ffffff;}
    }
  }

  .content {
    width: 90%;
    height: 55vh;
    margin:  0 auto;
    overflow-y: auto;
    padding-bottom: 30px;
  }

  .bottom {
    width: 100%;
    position: absolute;
    bottom: 2vh;
  }
</style>
